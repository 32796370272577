import './App.scss';
import {useState, useEffect, createContext} from 'react';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Main from './main';
import Menu from "./menu"
import Footer from "./footer"
import NotFound from "./notfound"
export const ThemeContext = createContext();

function App() {
const [theme,setTheme]=useState('black_theme');
useEffect(() => {
const getPreferredTheme=()=>window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ? 'black_theme' : 'white_theme';
//document.body.className=''
if (!document.body.classList.contains('body_animate')) window.setTimeout(()=>{document.body.classList.add('body_animate')},50)
setTheme(getPreferredTheme)
}, []);
useEffect(() => {
//  if  (document.body.className==='') document.body.className=theme;
//  else document.body.className="body_animate "+theme;
const listCls=document.body.classList
  listCls.forEach((el) =>
  {
    if ((el).indexOf('_theme')!==-1) document.body.classList.remove(el)
  })
document.body.classList.add(theme)
}, [theme]);
  return (
  <Router>
<ThemeContext.Provider value={{theme,setTheme}}>
<Menu />
</ThemeContext.Provider>
      <Routes>
        <Route path="/" element={<ThemeContext.Provider value={{theme,setTheme}}><Main /> </ThemeContext.Provider>}></Route>
          <Route path="*" element={<ThemeContext.Provider value={{theme,setTheme}}><NotFound /> </ThemeContext.Provider>} />
      </Routes>
<ThemeContext.Provider value={{theme,setTheme}}>
<div name="footer" aria-label="store"  aria-hidden="true" />
<Footer />
</ThemeContext.Provider>
  </Router>
  );
}


export default App;
