import React from 'react'
import {useEffect,useState, useRef, useContext} from 'react';
import './menu.scss'
import {/*animateDivScroll,*/useEvent/*,elementVisible*/,returnImgPath} from './functions'
import {ThemeContext} from './App'
var classNames = require('classnames');
function Menu(props)
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
const {theme,setTheme} = useContext(ThemeContext);
const switchTheme=()=>
{
  if (theme==='black_theme') setTheme('white_theme')
  if (theme==='white_theme') setTheme('black_theme')
}
/*
function hideBg(e)
{
setShowMobile(false)
}
*/
function open(item) 
{
setShowMobile(false)
//window.location.hash='#'+item
  if (typeof item.link!=='undefined') window.open(item.link);
  else if (typeof document.getElementById(item.a)==='undefined' || document.getElementById(item.a)===null) window.location.href='/#'+item.a
  else
   {
window.history.replaceState(null, null, document.location.pathname+'#'+item.a);
window.scrollTo({top: document.getElementById(item.a).offsetTop-80,behavior: "smooth"})
   }
}
function checkScroll() //подсвечивает пункт меню, добавляет фон меню при скролле
{
setMenuFix((window.scrollY!==0))
const menuItems=document.getElementsByClassName('menu_item')
var menu= [].slice.call(menuItems);
menu.sort((a, b) => {
const curIt=document.getElementById(a.dataset.key).offsetTop
const nextIt=document.getElementById(b.dataset.key).offsetTop
return curIt-nextIt

});
console.log(menu)
  if (typeof menu!=='undefined') 
   {
let curIt;
    for (let i = 0; i < menu.length; i++) menu[i].classList.remove("menu_item_act");
  /*  if (window.scrollY===0) 
     {
menu[0].classList.add("menu_item_act");
return true;
     }*/
    for (let i = menu.length-1; i >=0 ; i--)
     {
menu[i].classList.remove("menu_item_act")
curIt=document.getElementById(menu[i].dataset.key)
if (curIt && window.scrollY+80+window.innerHeight*0.5>curIt.offsetTop) {menu[i].classList.add("menu_item_act"); break}
    //if (elementVisible(menu[i].dataset.key)) menu[i].classList.add("menu_item_act")
     }
   }
}
const arr=[
{name:'О нас',a:'about'},
{name:'Как это работает?',a:'how'},
/*{name:'Активность',a:'activity'},
{name:'Партнеры',a:'partners'},*/
{name:'Импакт Карта',a:'map',link:"https://map.protone.app"},
{name:'Магазин Наград',a:'store',link:"https://store.protone.app"},
];
//const [scroll,setScroll]=useState(0)
const [menuFix,setMenuFix]=useState(false)
const [showMobile,setShowMobile]=useState(false)
//need to detect mobile /disable scroll
const [size,setSize]=useState({w:0,h:0})
const menuRef=useRef(null)
const i_resize=(e)=>
{
const wh=window.innerHeight, ww=window.innerWidth
setSize({w:ww,h:wh})
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => {
i_resize()
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
//
useEvent('scroll',checkScroll)
useEvent('load',checkScroll)
useEffect(() => { //disable scroll when mobile menu appears
const  ww=window.innerWidth;
//анимации для меню, с целью исправить баг видимости меню при ресайзе десктоп-мобилка
  //if (ww<=991) menuRef.current.classList.add("menu_wrapper_mobile");
  //else menuRef.current.classList.remove("menu_wrapper_mobile");
  if (ww<991)  menuRef.current.style.transition='1s all linear'
  else menuRef.current.style.transition=''
//
  if (showMobile && ww<=991) document.body.classList.add("disable_scroll");
  else document.body.classList.remove("disable_scroll");
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[showMobile,size.w]);
  return (
<div className={classNames("menu",{"menu_sticky":menuFix})}>
  <img alt="Protone" src={returnImgPath(theme,"logo.svg")} className="logo" onClick={()=>window.location.href="https://protone.app"} />
	<div ref={menuRef}className={classNames("menu_wrapper",{"menu_wrapper_show":showMobile},{"menu_wrapper_mobile":showMobile})} /*onClick={()=>hideBg()}*/>
	  {arr.map((it,i)=><div className="menu_item" onClick={()=>open(it)} key={i} id={'menu_'+it.a} data-key={it.a}>{it.name}</div>)}
{
(size.w<991)?<div className="menu_mobile_wrapper">
<div className="menu_mobile_top_bg"></div>
<div className="menu_hr"></div>
<div className="menu_ftitle">Protone - твой проводник в мир яркой городской жизни</div>
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_apple.svg")} alt="" className="menu_app_apple" /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"button_google.svg")} alt="" className="menu_app_google" /></a>
<div className="menu_btitle">Загрузи приложение и присоединяйся к самому активному сообществу.</div>
<div className="menu_social">
<a href="https://t.me/protone" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_tel.svg")} className="soc_img" alt="" /></a>
<a href="https://www.tiktok.com/@protone.app" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_tik.svg")} className="soc_img" alt="" /></a>
<a href="https://www.vk.com/protone_app" target="_blank" rel="noreferrer"><img src={returnImgPath(theme,"soc_vk.svg")} className="soc_img" alt="" /></a>
</div>
		<div className="menu_copy">©2024 - Protone  |   All right reserved</div>

</div>:''
}
<img alt="Protone" src={returnImgPath(theme,"theme.svg")} onClick={switchTheme} className="menu_theme" />
	  </div>
<div className="menu_button" onClick={()=>setShowMobile(!showMobile)}><img src={(showMobile)?returnImgPath(theme,"menu_close.svg"):returnImgPath(theme,"menu.svg")} alt="" /></div>
</div>
  );
}

export default Menu;