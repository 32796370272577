// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*not found*/
.notfound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 290px);
  padding-left: 56px;
  padding-right: 56px;
}
.notfound .title {
  margin-top: 88px;
  font-family: "Atom-Medium";
  font-size: 80px;
  text-align: center;
}
.notfound .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Atom-Medium";
  font-size: 18px;
  text-align: center;
}
.notfound A {
  color: var(--accent-color);
  /*text-decoration:underline;*/
  transition: 0.3s all linear;
}
.notfound A:HOVER {
  color: var(--accent-color);
}`, "",{"version":3,"sources":["webpack://./src/notfound.scss"],"names":[],"mappings":"AAAA,YAAA;AACA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,WAAA;EACA,2BAAA;EACA,kBAAA;EACA,mBAAA;AAAA;AACC;EAED,gBAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;AAAA;AAEE;EAEF,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;AADA;AAGE;EAEF,0BAAA;EACA,6BAAA;EACA,2BAAA;AAFA;AAGA;EAAS,0BAAA;AAAT","sourcesContent":["/*not found*/\n.notfound\n{\ndisplay:flex;\nalign-items:center;\njustify-content:center;\nflex-direction:column;\nwidth:100%;\nheight:calc(100vh - 290px);\npadding-left:56px;\npadding-right:56px;\n\t.title\n\t{\nmargin-top:88px;\nfont-family: 'Atom-Medium';\nfont-size:80px;\ntext-align:center;\n\t}\n\t .text\n\t {\ndisplay:flex;\nflex-direction:column;\nalign-items:center;\nfont-family: 'Atom-Medium';\nfont-size:18px;\ntext-align:center;\n\t }\n\t A\n\t {\ncolor:var(--accent-color);\n/*text-decoration:underline;*/\ntransition:0.3s all linear;\n&:HOVER {color:var(--accent-color);}\n\t }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
