import './main.scss';
import {useState, useEffect, useContext} from 'react';
import {ThemeContext} from './App'
import {returnImgPath, stripTags, useEvent} from './functions'
import Slider from "./slider"
import SliderOverflow from "./sliderOverflow"
function goApp()
{
  if (/android/.test( navigator.userAgent.toLowerCase())) window.open("https://redirect.appmetrica.yandex.com/serve/316668474296386215?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
  else window.open("https://redirect.appmetrica.yandex.com/serve/965186825066976482?click_id=%7BLOGID%7D&search_term=%7Bkeyword%7D&device_type=%7Bdevice_type%7D&region_name=%7Bregion_name%7D&source_type=%7Bsource_type%7D&phrase_id=%7Bphrase_id%7D&source=%7Bsource%7D&position_type=%7Bposition_type%7D&campaign_id=%7Bcampaign_id%7D")
}
function Header()
{
const {theme} = useContext(ThemeContext);
let main_slider=[]
/*
main_slider.push(<div key={1} className="main_slide main_slide_1"  style={{backgroundImage:`url(${returnImgPath(theme,"main_bg.svg")})`}}>
<img src={returnImgPath(theme,"main_car.png")} alt="" className="main_car" />
	</div>)*/
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_1"  style={{backgroundImage:`url(${returnImgPath(theme,"main/1_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_2"  style={{backgroundImage:`url(${returnImgPath(theme,"main/2_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_3"  style={{backgroundImage:`url(${returnImgPath(theme,"main/3_scene.jpg")})`}}>
	</div>)
main_slider.push(<div key={'main_slide_'+main_slider.length} className="main_slide main_slide_4"  style={{backgroundImage:`url(${returnImgPath(theme,"main/4_scene.jpg")})`}}>
	</div>)
const bubble_arr=[
{title:"Красивая и тихая беседка в парке",
text:"“В сентябре особенно красиво цветут яблони напротив”",
copy:"Александра",
img:returnImgPath(theme,"main/1_scene_avatar.png")
},
{title:"Protone Impact<br />Fest 2024",
text:"Приходите слушать музыку, лекции и получать протоны за активности!",
copy:"Protone Team",
img:returnImgPath(theme,"main/2_scene_avatar.png")
},
{title:"Станция зарядки электрокаров",
text:"“Удобная станция, 6 зарядок, почти всегда пустует как минимум две”",
copy:"Василий",
img:returnImgPath(theme,"main/3_scene_avatar.png")
},
{title:"Спортивная<br />площадка",
text:"“На площадке есть всё: брусья, тренажеры, баскетбольное поле”",
copy:"Марина",
img:returnImgPath(theme,"main/4_scene_avatar.png")
},
]
let bubble_slider=[]
let preload=[]
bubble_arr.forEach((el,index) =>{preload.push([el.img])})
  bubble_arr.forEach((el,index) =>
  {
bubble_slider.push(<div key={'bottom_popup_'+index} className="bottom_popup"  style={{backgroundImage:`url(${returnImgPath(theme,"main_popup.svg")})`}}>
		<div className="bottom_popup_title" dangerouslySetInnerHTML={{__html: stripTags(el.title)}}></div>
		<div className="bottom_popup_text">{el.text}</div>
		<div className="bottom_popup_bottom">
			<img src={el.img} alt="" className="bottom_popup_img" />
			<div className="bottom_popup_copy">{el.copy}</div>
		</div>
	</div>
)
			}
)
let  arrSlider=[]
for (var i=0;i<main_slider.length; i++) arrSlider.push(<div key={"arr_slider_"+i}><div className="main_slider">{main_slider[i]}</div><div className="main_buble_slider">{bubble_slider[i]}</div></div>)
return(
<div className="main">
<Slider arr={arrSlider} autoplay={7000} disableTouch={true} preload={preload}  />
	<div className="main_top_title"><span>Protone</span> — твой проводник в мир яркой городской жизни.​</div>
	<img  src={returnImgPath(theme,"qr.png")} alt="" className="main_qr" />
	<div onClick={()=>goApp()} className="main_button button">Скачать приложение</div>
</div>
)
}
	
function AboutOne({img,title,text})
{
return(
<div className="about_one">
	<img src={img} alt="" className="about_img" />
		<div className="about_bubble">
	<div className="about_one_title">{title}</div>
	<div className="about_one_text">{text}</div>
		</div>
</div>
)
}
function About()
{
const {theme} = useContext(ThemeContext);
const arr=[
{img:returnImgPath(theme,"about_1.png"),
title:"Мы — приложение с альтернативной программой лояльности",
text:"Выполняй миссии и получай протоны, которые можно обменять на реальное вознаграждение.​ Ни одно полезное дело не останется незамеченным."
},
{img:returnImgPath(theme,"about_2.png"),
title:"Ощути пульс большого города с Protone",
text:"Проходи образовательные и социально значимые миссии, а также прокачивай себя и свое окружение."
},
{img:returnImgPath(theme,"about_3.png"),
title:"Сообщество",
text:"Мы предлагаем не только широкий спектр миссий в приложении, но и привилегированное участие игроков​ в наших и партнерских мероприятиях - фестивалях, вечеринках и конкурсах. Здесь вы найдете единомышленников и друзей."
},
{img:returnImgPath(theme,"about_4.png"),
title:"Загрузи новый контекст реальности​",
text:"Каждое твое действие имеет значение. С Protone ты создаешь цифровой след новой реальности и делаешь мир лучше.​​"
},
]
const arrSlider=[]
arr.forEach((it,i) =>arrSlider.push(<AboutOne img={it.img} title={it.title} text={it.text} />))
return(
<div className="about" style={{backgroundImage:`url(${returnImgPath(theme,"about_bg.svg")}),var(--about-bg)`}} id="about">
<div className="about_title">О нас</div>
	<div className="about_slider">
<SliderOverflow arr={arrSlider} />
	</div>
</div>)
}
function HowOne({img,title,text,number})
{
return(
<div className="how_one" style={{backgroundImage:`url(${img})`}}>
	<div className="how_one_number">{number}</div>
	<div className="how_one_title">{title}</div>
	<div className="how_one_text">{text}</div>
</div>
)
}
function How()
{
/*
			<div className="how_slider_wrapper">
{arr.map((it,i)=><HowOne img={it.img} title={it.title} text={it.text} number={i+1} />)}
			</div>
*/
const {theme} = useContext(ThemeContext);
const arr=[
{img:returnImgPath(theme,"how_1.png"),
title:"Скачай и авторизуйся в приложении",
text:"Зарегистрируйся, настрой свой профиль и получи приветственные баллы.  А еще можешь создать своего цифрового аватара."
},
{img:returnImgPath(theme,"how_2.png"),
title:"Выбери миссии по душе",
text:"Исследуй разнообразные локации на карте, где мы собрали от образовательных миссий до захватывающих челленджей, которые помогут развить твои навыки и получить новые знания."
},
{img:returnImgPath(theme,"how_3.png"),
title:"Выполняй задания и получай награды",
text:"Проходи миссии один, либо с друзьями, зарабатывай протоны – виртуальные баллы, и присоединяйся к коммьюнити."
},
{img:returnImgPath(theme,"how_4.png"),
title:"Обменивай протоны на реальные призы",
text:"Используй протоны для обмена на ценные награды: сервисы, фирменный мерч и многое другое."
}
]
const arrSlider=[]
arr.forEach((it,i) =>arrSlider.push(<HowOne img={it.img} title={it.title} text={it.text} number={i+1} />))
return(
<div className="how" id="how">
<div className="how_title">Как это работает</div>
	<div className="how_slider">
<SliderOverflow arr={arrSlider} />
	</div>
</div>)
}
function Store()
{

const [bigsize,setBigSize]=useState(false);
const i_resize=(e)=>
{
window.setTimeout(function(){
//setBigSize(window.innerWidth>760?true:false)
setBigSize(window.innerWidth)
},10)
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => { 
i_resize() }// eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
const {theme} = useContext(ThemeContext);
return(
<div className="store"  id="store">
	<div className="store_left"  style={{backgroundImage:`url(${returnImgPath(theme,"store_all_stars.svg")})`}}>
<div className="store_left_title">Система вознаграждения</div>
		<div className="store_left_wrapper">
<img src={returnImgPath(theme,(bigsize>661)?"store_protone.png":"store_protone_mob.png")} alt="Протоны основная игровая валюта" className="store_protone" />
<img src={returnImgPath(theme,(bigsize>661)?"store_atom.png":"store_atom_mob.png")} alt="Атомы дополнительная игровая валюта" className="store_atom" />
		</div>
<div className="store_left_subtitle">В Protone ни одно дело не останется незамеченным.
<br /><br />
<span>Все действия пользователей вознаграждаются виртуальными баллами — протонами и атомами.</span>
</div>
	</div>
	<div className="store_right" style={{backgroundImage:`url(${returnImgPath(theme,(bigsize>760)?"store_bg.jpg":"store_bg_small.jpg")})`}}>
		<div className="store_right_popup">
<div className="store_right_title_wrapper"><img src={returnImgPath(theme,"store_coin.svg")} className="store_right_coin" alt="" /><div className="store_right_title">Магазин наград</div></div>
<div className="store_right_text">Это наш стор, где вы можете обменять заработанные в приложении протоны на реальные награды: от подписок на онлайн-сервисы, лимитированного мерча до современных гаджетов.</div>
<a href="https://store.protone.app" target="_blank" rel="noreferrer" className="store_button button">Перейти к магазину</a>
		</div>
	</div>
</div>
)
}
function OurMap()
{
const {theme} = useContext(ThemeContext);
return(
<div className="map" id="map">
	<div className="map_title">Импакт Карта</div>
	<div className="map_wrapper">
		<div className="map_left" style={{backgroundImage:`url(${returnImgPath(theme,"map_left.png")})`}}>
		</div>
		<div className="map_right">
			<div className="map_right_wrapper">
			<img src={returnImgPath(theme,"map_right.png")} alt="" className="map_right_img" />
			<div className="map_right_title_mobile">Импакт Карта</div>
			<div className="map_right_text">
Protone помогает пользователям интегрировать полезные привычки в их повседневную жизнь
<br /><br />
Наша карта постоянно расширяется! Регулярно появляются новые партнерские локации.
			</div>
<a href="https://map.protone.app" target="_blank" rel="noreferrer" className="map_button button">Перейти к карте</a>
			</div>
		</div>
	</div>
</div>	
)
}
function Main()
{
useEffect(() => {
  if (window.location.hash!=='') 
{
//исправляет баг некоторых браузеров по работе хеша и реакта
	window.setTimeout(()=>{
const cur=window.location.hash
window.location.hash=''
window.location.hash=cur
  },30)
}	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
return (
<div>
<Header />
<div name="about" aria-label="about"  aria-hidden="true" />
<About />
<div name="how" aria-label="how"  aria-hidden="true" />
<How />
<div name="store" aria-label="store"  aria-hidden="true" />
<Store />
<div name="map" aria-label="map"  aria-hidden="true" />
<OurMap />
</div>
	)
}

export default Main;
