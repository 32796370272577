import React from 'react'
import {useEffect,useState, useRef} from 'react';
import {returnNext} from './functions.js'
//простой слайдер для показа div блоков
//параметры
//arr=Array - массив со слайдами
//autoplay=Int|false - нужна ли автопрокрутка
//showpoints=true|false - показывать ли точки со слайдером
function Slider(props)
{
const [n,setN]=useState(0);
const [stopTimer,setStopTimer]=useState(false)
const [loading,setLoading]=useState(false)
const stopRef=useRef(null)
stopRef.current=stopTimer
const nRef=useRef(null)
nRef.current=n
const arr=props.arr
const item=arr[nRef.current]
//generate points
const points=<div className="slider_points">{arr.map((item,i)=><div key={i} className={(i===n)?"slider_point slider_point_active":"slider_point"} onClick={()=>{setStopTimer(true);setN(i);}} />)}</div>

//mobile swipe
const [touchStart, setTouchStart]=useState(0);
const [touchEnd, setTouchEnd]=useState(0);
function etouchStart(e) {setTouchStart(e.targetTouches[0].clientX);e.stopPropagation() }
function etouchMove(e) {;setTouchEnd(e.targetTouches[0].clientX);}
function etouchEnd(e)
{
if (props.disableTouch===true) return false;
  //if (Math.abs(touchStart-touchEnd)<10 || touchEnd===0) return false;
let next=(touchStart-touchEnd>50 && touchEnd!==0)?returnNext(false,nRef.current,arr.length):(touchStart-touchEnd<-50 && touchEnd!==0)?returnNext(true,nRef.current,arr.length):false
//console.log(n,next)
setTouchEnd(0)
setTouchStart(0)
  if (next!==false) 
   {
setStopTimer(true) //stop timer when slide by touch
   setN(next)
   }
}
//end swipe

useEffect(() => {
preload(returnNext(false,nRef.current,arr.length))
let interval=window.setInterval(()=>
	{
if (loading) return;
if (typeof props.autoplay==='undefined' || props.autoplay===null || stopRef.current===true) return;
const next=returnNext(false,nRef.current,arr.length)
//console.log('go',next)
setN(next)
preload(returnNext(false,(nRef.current+1),arr.length))
	},props.autoplay)
  return () => {
    clearInterval(interval);
  };// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
//preload next image
const preload=(next)=>
{
const preloadPromise=(pic)=> 
{
setLoading(true)
const img=new Image()
return new Promise((resolve, reject)=>{
img.src=pic
img.onload=()=>resolve('')
img.onerror=()=>resolve('')
})
}
  if (typeof props.preload!=='undefined' &&props.preload!==null)
   {
    preloadPromise(props.preload[next][0]).then(()=>
     {
//console.log('loaded',next)
setLoading(false)
//setN(next)
     })
   }
//console.log('start load',next)
}
return(
<div onTouchStart={(e)=>etouchStart(e)} onTouchEnd={(e)=>etouchEnd(e)} onTouchMove={(e)=>etouchMove(e)} style={{touchAction:'pan-y'}}>
{item}
{(props.showpoints!==false)?points:''}
</div>
)
}

export default Slider;